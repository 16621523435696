@import 'animate.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

* {
  box-sizing: border-box;
}

// Color variables
$black: #010101;
$background: #021027;
$salmon: #fc8a68;
$soft-peach: #fefdfd;
$amethyst-smoke: #a095af;
$copper-rust: #964b48;
$button-hover: #5f2e8e;

// Fonts
$primary-font: 'Bungee', cursive;
$secondary-font: 'Audiowide', cursive;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: $soft-peach;
  background-color: #021027;
  // overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden
}

// Scrollbar

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $salmon;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fc734a;
}
