@import '../../App.scss';

// STYLES FOR MOBILE

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;

  .typing-text {
    position: absolute;
    .portfolio-title {
      width: 100%;
      font-size: 1rem;
      text-align: center;
      margin-top: 85px;
      font-size: 1.4rem;
      text-transform: capitalize;
      font-family: $primary-font;
    }
  }

  .left-right-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 160px;
  }

  // T animation section
  .t-animation-wrapper {
  }

  // White text section text
  .text {
    div {
      color: $soft-peach;
      font-family: $primary-font;
      margin-bottom: 10px;
    }
  }

  .text {
    width: 100%;
    text-align: center;
    margin-top: 50px;

    // Orange text
    .frontend-react-dev {
      color: $salmon;
      font-family: $secondary-font;
      letter-spacing: 3px;
      animation: fadeIn 1s 1.8s backwards;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    // Contact me button
    a {
      color: $soft-peach;
      text-decoration: none;
      margin-top: 20px;
      z-index: 10;
      position: relative;
      display: inline-block;
      padding: 0 1em;
      text-transform: uppercase;
      text-align: center;
      font-size: 12px;
      font-size: 0.8rem;
      line-height: 1.91667;
      color: #2e1e43;
      background-color: #ff8c67;
      letter-spacing: 0.2em;
      font-weight: 600;
      font-family: $secondary-font;
      width: 40%;
      font-size: 16px;
      line-height: 1.4375;
      padding: 0.7em;
      margin-bottom: 1.5em;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -ms-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      animation: fadeIn 1s 1.8s backwards;
      width: 200px;
    }

    a:hover {
      background-color: $soft-peach;
      color: $background;
    }
  }
}

// STYLES FOR TABLETS

@media screen and (min-width: 768px) {
  .home-page {
    .typing-text {
      .portfolio-title {
        font-size: 2.4rem;
      }
    }

    // T animation section
    .t-animation-wrapper {
      margin-bottom: 500px;
    }

    // White text section text
    .text {
      bottom: 140px;
      div {
        color: $soft-peach;
        font-family: $primary-font;
        margin-bottom: 10px;
        font-size: 2rem;
      }
    }
  }
}

// DESKTOPS
@media screen and (min-width: 1025px) {
  .home-page {
    .typing-text {
      .portfolio-title {
        font-size: 32px !important;
      }
    }

    .left-right-wrapper {
      flex-direction: row;
      position: relative;
      margin-left: 200px;
      bottom: 100px;
    }

    .t-animation-wrapper {
      position: relative;
      top: 100px;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      position: relative;

      div {
        text-align: start;
        font-size: 28px;
      }
    }
  }
}

// LARGE SCREEN DESKTOPS
@media screen and (min-width: 1600px) {
  .home-page {
    .typing-text {
      .portfolio-title {
        font-size: 52px !important;
      }
    }

    .left-right-wrapper {
    }

    .t-animation-wrapper {
    }

    .text {
      div {
        font-size: 48px;
      }
    }
  }
}
