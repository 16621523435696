@import '../../App.scss';

.text-animate {
  display: inline-block;
  font-family: $primary-font;
  opacity: 0;
  animation: bounceIn 1s ease-in-out 3s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

.text-animate-hover {
  min-width: 10px;
  display: inline-block;
  animation-fill-mode: both;
  cursor: auto;

  &:hover {
    animation: rubberBand 1s;
    color: $salmon;
    cursor: default;
  }
}

@for $i from 1 through 70 {
  .text-animate._#{$i} {
    animation-delay: #{$i / 80}s;
  }
}
