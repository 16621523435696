@import '../../App.scss';

// MOBILE
.skills-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .text-zone-skills {
    // margin-top: 5000px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 2rem;

    .skills-title {
      span {
        font-size: 2rem !important;
        font-family: $primary-font;
        color: $salmon;
      }
    }

    .sentence {
      font-family: $secondary-font;
      font-size: 1.5rem;
      margin-bottom: -20px;
    }
  }

  .skills-right-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .individual-project-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50vw;
      margin-top: 40px;

      p {
        font-size: 1.5rem;
      }
    }
  }
}

// TABLETS

@media screen and (min-width: 768px) {
 
  

  .skills-page {
    .skills-right-side {
      display: grid;
      grid-template-columns: 25vw 25vw;
      grid-gap: 100px;

      .individual-project-wrapper {
        width: 25vw;
      }
    }
  }
}

// DESKTOPS
@media screen and (min-width: 1025px) {
  .skills-right-side {
    grid-template-columns: 10vw 10vw 10vw 10vw  !important;

    .individual-project-wrapper {
      width: 100px !important;
    }
  }
}



// LARGE SCREEN DESKTOPS
@media screen and (min-width: 1600px) {
  .skills-right-side {
    grid-template-columns: 10vw 10vw 10vw 10vw 10vw !important;

    .individual-project-wrapper {
      width: 150px !important;
    }
  }
}



