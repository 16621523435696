.page {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
}

#hide-background {
  opacity: 0;
  overflow: hidden;
}

@media screen and (min-width: 1025px) {

  
}
