@import '../../App.scss';

.navigation-wrapper {
  display: none;
}

@media screen and (max-width: 768px) {
  #icons {
    font-size: 28px !important;
  }
  nav {
    top: 24% !important;
  }

  .nav-2-icons {
    font-size: 28px !important
    ;
  }
}

@media screen and (max-height: 700px) {
  .links-wrapper-2 {
    position: absolute;
    bottom: -35px !important;
  }
}

@media screen and (min-width: 1025px) {
  .navigation-wrapper {
    background: $black;
    position: fixed;
    display: block;
    width: 100px;
    height: 100%;
    z-index: 3;
    // min-height: 500px;
    opacity: 1;
    justify-content: center;

    //   Nav page links
    nav {
      display: block;
      text-align: center;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      a {
        display: block;
        line-height: 50px;
        height: 50px;
        position: relative;
        color: $soft-peach;

        #icons {
          // color: $soft-peach;
          font-size: 32px !important;
          text-decoration: none;
        }

        #icons2 {
          font-size: 32px !important;
          text-decoration: none;
        }

        #icons:hover {
          color: $salmon;
        }
      }
    }

    //   Nav github and linkedin links
    li {
      list-style: none;
      display: block;

      a {
        .nav-2-icons:hover {
          color: $salmon;
        }
      }
    }
  }

  #active-icon {
    color: salmon !important;
  }
}

@media screen and (min-width: 1600px) {
  .navigation-wrapper {
    background: $black;
    position: fixed;
    display: block;
    width: 100px;
    height: 100%;
    z-index: 3;
    // min-height: 500px;
    opacity: 1;
    justify-content: center;

    //   Nav page links
    nav {
      a {
        #icons {
          // color: $soft-peach;
          font-size: 48px !important;
        }

        #icons2 {
          font-size: 48px !important;
        }
      }
    }
  }
}
