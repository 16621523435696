@import '../../App.scss';

// MOBILE

.about-page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  // overflow-y: hidden;
  // overflow-x: hidden;

  .right-side-about {
    height: 0;
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin: 60px;

    .react-logo {
    }
  }

  .text-animate-hover {
    &:hover {
      color: $soft-peach;
    }
  }

  .text-zone-about {
    position: absolute;
    top: 0;
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .about-me-title {
      width: 100%;
      text-align: center;
      margin-top: 30px;
      font-size: 1.5rem;
      margin-bottom: 20px;

      font-family: $primary-font;
      position: relative;
      color: $salmon !important;
      letter-spacing: 2px;
    }

    // #1
    .about-me-text-1 {
      -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 2s; /* Firefox < 16 */
      -ms-animation: fadein 2s; /* Internet Explorer */
      -o-animation: fadein 2s; /* Opera < 12.1 */
      animation: fadein 2s;
    }

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    // #2
    .about-me-text-2 {
      -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
      -moz-animation: fadein 3s; /* Firefox < 16 */
      -ms-animation: fadein 3s; /* Internet Explorer */
      -o-animation: fadein 3s; /* Opera < 12.1 */
      animation: fadein 3s;
    }

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

  // #3
  .about-me-text-3 {
    -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 4s; /* Firefox < 16 */
    -ms-animation: fadein 4s; /* Internet Explorer */
    -o-animation: fadein 4s; /* Opera < 12.1 */
    animation: fadein 4s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

// TAbLETS
@media screen and (min-width: 768px) {
  .about-page {
    .right-side-about {
      height: 0;
      width: 0;
      position: absolute;
      left: 0;
      top: 40px;
      margin: 60px;
      margin-left: 180px;
    }

    .text-zone-about {
      font-size: 1.6rem;
      .about-me-text {
        margin-top: 10px;
      }

      .text-top {
        margin-top: 120px;
      }
    }
  }
}

// DESKTOPS
@media screen and (min-width: 1025px) {
  .about-page {
    position: relative;
    right: 100px !important;
    bottom: 200px !important;

    .right-side-about {
      position: relative;
      margin-left: 900px;
      margin-top: 500px;
    }

    .text-zone-about {
      position: absolute;
      width: 35% !important;
      justify-content: start !important;
      align-items: flex-start;
      margin-top: 300px !important;
      margin-right: 280px;
      margin-left: 50px;
      font-size: 20px;

      .about-me-title {
        font-size: 32px !important;
        text-align: start !important;
        margin-bottom: -100px !important;
      }
    }
  }
}

// LARGE SCREEN DESKTOPS
@media screen and (min-width: 1700px) {
  .about-page {
    position: relative;
    right: 100px !important;
    margin-top: 200px;

    .right-side-about {
      position: relative;
      margin-left: 1400px;
      margin-top: 500px;
    }

    .text-zone-about {
      position: absolute;
      width: 35% !important;
      justify-content: start !important;
      align-items: flex-start;
      margin-top: 200px !important;
      margin-right: 600px;
      font-size: 28px;

      .about-me-title {
        font-size: 40px !important;
        text-align: start !important;
        margin-bottom: -100px !important;
      }
    }
  }
}
