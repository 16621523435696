@import '../../App.scss';

.projects-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;

  .project-page-title {
    margin-top: 30px;
    margin-bottom: 40px;
    margin-right: 30px !important;
    color: $salmon !important;
    font-size: 2rem !important;
    text-align: center;
    width: 100%;
  }

  // Projects bottom section
  .scene {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .card-wrapper {
      margin-bottom: 40px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        text-decoration: none;

        .project-title {
          font-size: 16px !important;
          color: white;
          margin-bottom: 1.2rem;
          text-align: center;
          font-family: $secondary-font;
        }
      }

      .card {
        position: relative;
        width: 260px;
        height: 210px;
        color: white;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        transform-style: preserve-3d;
        &:hover {
          // transform: rotateY(0.5turn);
          opacity: 0.5 !important;
        }

        .card__face {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          transition: 2s ease-in-out;
          -webkit-box-reflect: below 0
            linear-gradient(transparent, transparent, rgba(0, 0, 0, 0));

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

// TABLETS
@media screen and (min-width: 768px) {
  .projects-page {
    .project-page-title {
      font-size: 3rem !important;
    }

    // Projects bottom section

    .cards {
      display: grid;
      grid-template-columns: 40% 40%;
      grid-gap: 80px;

      .card-wrapper {
        a {
          text-decoration: none;

          .project-title {
            font-size: 16px !important;
            text-align: center;
            width: 100%;
          }
        }

        .card {
          position: relative;
          width: 330px;
          height: 250px;
        }
      }
    }
  }
}

// DESKTOPS
@media screen and (min-width: 1025px) {
  .projects-page {
    .project-page-title {
      font-size: 32px !important;
    }

    // Projects bottom section

    .cards {
      display: grid;
      grid-template-columns: 25% 25%;
      grid-gap: 200px;

      .card-wrapper {
        a {
          div {
            font-family: $secondary-font;
            font-size: 24px;
            letter-spacing: 1px;
            text-decoration: none;
            background-image: linear-gradient($salmon, $salmon);
            background-size: 0% 0.1em;
            background-position-y: 100%;
            background-position-x: 0%;
            background-repeat: no-repeat;
            transition: background-size 0.2s ease-in-out;
          }

          div:hover,
          div:focus,
          div:active {
            background-size: 100% 0.1em;
          }

          .project-title {
            font-size: 22px !important;
            text-align: center;
            width: 100%;
            margin-bottom: 2rem;
          }
        }

        .card {
          position: relative;
          width: 400px;
          height: 300px;
        }
      }
    }
  }
}

// LARGE SCREEN DESKTOPS
@media screen and (min-width: 1800px) {
  .projects-page {
    .project-page-title {
      font-size: 3rem !important;
    }

    // Projects bottom section

    .cards {
      display: grid;
      grid-template-columns: 25% 25% 25%;
      grid-gap: 80px;

      .card-wrapper {
        .project-title {
          font-size: 28px !important;
          text-align: center;
          width: 100%;
          margin-bottom: 2rem;
        }

        .card {
          position: relative;
          width: 500px;
          height: 380px;
        }
      }
    }
  }
}

// Star animation background
.background {
  justify-content: center;
  align-content: center;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: calc(100% - 200px);
  height: 100%;
  z-index: -1;

  mask-image: radial-gradient(
    white 0%,
    white 30%,
    transparent 80%,
    transparent
  );
}

.circle-container {
  $particleNum: 200;
  $particleColor: hsl(180, 100%, 80%);

  position: absolute;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    mix-blend-mode: screen;
    background-image: radial-gradient(
      hsl(180, 100%, 80%),
      hsl(180, 100%, 80%) 10%,
      hsla(180, 100%, 80%, 0) 56%
    );

    animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

    @keyframes fade-frames {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes scale-frames {
      0% {
        transform: scale3d(0.4, 0.4, 1);
      }

      50% {
        transform: scale3d(2.2, 2.2, 1);
      }

      100% {
        transform: scale3d(0.4, 0.4, 1);
      }
    }
  }

  $particleBaseSize: 8;

  @for $i from 1 through $particleNum {
    &:nth-child(#{$i}) {
      $circleSize: random($particleBaseSize);
      width: $circleSize + px;
      height: $circleSize + px;

      $startPositionY: random(10) + 100;
      $framesName: 'move-frames-' + $i;
      $moveDuration: 28000 + random(9000) + ms;

      animation-name: #{$framesName};
      animation-duration: $moveDuration;
      animation-delay: random(37000) + ms;

      @keyframes #{$framesName} {
        from {
          transform: translate3d(
            #{random(100) + vw},
            #{$startPositionY + vh},
            0
          );
        }

        to {
          transform: translate3d(
            #{random(100) + vw},
            #{- $startPositionY - random(30) + vh},
            0
          );
        }
      }

      .circle {
        animation-delay: random(4000) + ms;
      }
    }
  }
}
