@import '../../App.scss';

.entire-wrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;

  .menu-icon {
    display: block;
    position: fixed;
    top: 0;
    right: -15px;
    transform: translate(-100%, 60%);
    cursor: pointer;
    z-index: 9999;

    .close {
      color: black !important;
      z-index: 1000;
    }
  }

  .nav-menu {
    opacity: 0;
    position: relative;
  }

  .active {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: $soft-peach !important;
    z-index: 999;

    ul {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      opacity: 1;
      transition: 5s;

      .nav-links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50px;
        text-align: center;
        text-decoration: none;
        font-family: $secondary-font;
        font-weight: bold;
        font-size: 1.8rem;
        text-decoration: none;
        z-index: 999;

        .nav-item {
          position: relative;
          right: 20px;
          color: $button-hover;
          margin-bottom: 0px;
          list-style-type: none;
          top: 5px !important;
          transition: 0.3s;
        }

        .nav-2 {
          color: $salmon;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  svg {
    font-size: 4rem !important;
  }

  .nav-links {
    font-size: 3.5rem !important;
  }
}

@media screen and (min-width: 1025px) {
  .entire-wrapper {
    display: none;
  }
}
