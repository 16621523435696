$color: #fc8a68;

@mixin flexBox($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

body {
  section {
    width: 60vh;
    height: 60vh;
    transition: 200ms ease;
    @include flexBox(row, center, center);

    .logo {
      position: absolute;
      &:nth-child(4) {
        // Cicrle kenel
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $color;
        animation: animateFade 5s infinite linear;
      }

      @for $i from 1 through 3 {
        // Cicrle around
        &:nth-child(#{$i}) {
          width: 50vh;
          height: 50vh;
          border-top: 10px solid $color;
          border-left: 12px solid $color;
          border-radius: 50%;
          animation: animateSpin-#{$i} 5s infinite linear;
        }
      }
    }
  }

  section:hover {
    transform: scale(1.3);
  }
}

@for $i from 1 through 11 {
  @keyframes animateSpin-#{$i} {
    0% {
      transform: rotatez(120deg * $i) rotatex(50deg) rotatez(0deg);
    }
    100% {
      transform: rotatez(120deg * $i) rotatex(50deg) rotatez(360deg);
    }
    0%,
    20%,
    40%,
    60%,
    80%,
    100% {
      box-shadow: -2px -2px 20px $color;
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      box-shadow: -2px -2px 5px $color;
    }
  }
}

@keyframes animateFade {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transform: scale(0.8);
    box-shadow: -2px 0px 20px $color;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: scale(1);
    box-shadow: -2px 0px 5px $color;
  }
}

// Responsiveness

// MOBILE
.logo {
  position: absolute;
  &:nth-child(4) {
    // Cicrle kenel
    width: 10px !important;
    height: 10px !important;
  }

  @for $i from 1 through 3 {
    // Cicrle around
    &:nth-child(#{$i}) {
      width: 8vh !important;
      height: 8vh !important;
    }
  }
}

// TABLETS
@media screen and (min-width: 768px) {
  .logo {
    position: absolute;
    &:nth-child(4) {
      // Cicrle kenel
      width: 10px !important;
      height: 10px !important;
    }

    @for $i from 1 through 3 {
      // Cicrle around
      &:nth-child(#{$i}) {
        width: 14vh !important;
        height: 14vh !important;
      }
    }
  }
}

// DESKTOPS
@media screen and (min-width: 1025px) {
  .logo {
    position: absolute;
    &:nth-child(4) {
      // Cicrle kenel
      width: 10px !important;
      height: 10px !important;
    }

    @for $i from 1 through 3 {
      // Cicrle around
      &:nth-child(#{$i}) {
        width: 30vh !important;
        height: 30vh !important;
      }
    }
  }
}


// LARGE SCREEN DESKTOPS
@media screen and (min-width: 1700px) {
  .logo {
    position: absolute;
    &:nth-child(4) {
      // Cicrle kenel
      width: 10px !important;
      height: 10px !important;
    }

    @for $i from 1 through 3 {
      // Cicrle around
      &:nth-child(#{$i}) {
        width: 40vh !important;
        height: 40vh !important;
      }
    }
  }
}




















// @media screen and (max-width: 1600px) {
//   .logo {
//     position: absolute;
//     &:nth-child(4) {
//       // Cicrle kenel
//       width: 40px;
//       height: 40px;
//     }

//     @for $i from 1 through 3 {
//       // Cicrle around
//       &:nth-child(#{$i}) {
//         width: 30vh !important;
//         height: 30vh !important;
//       }
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .logo {
//     position: absolute;
//     &:nth-child(4) {
//       // Cicrle kenel
//       width: 30px !important;
//       height: 30px !important;
//     }

//     @for $i from 1 through 3 {
//       // Cicrle around
//       &:nth-child(#{$i}) {
//         width: 25vh !important;
//         height: 25vh !important;
//       }
//     }
//   }
// }

// @media screen and (max-width: 1024px) {
//   .logo {
//     position: absolute;
//     &:nth-child(4) {
//       // Cicrle kenel
//       width: 20px !important;
//       height: 20px !important;
//     }

//     @for $i from 1 through 3 {
//       // Cicrle around
//       &:nth-child(#{$i}) {
//         width: 20vh !important;
//         height: 20vh !important;
//       }
//     }
//   }
// }

// @media screen and (max-width: 768px) {
//   .logo {
//     position: absolute;
//     &:nth-child(4) {
//       // Cicrle kenel
//       width: 15px !important;
//       height: 15px !important;
//     }

//     @for $i from 1 through 3 {
//       // Cicrle around
//       &:nth-child(#{$i}) {
//         width: 15vh !important;
//         height: 15vh !important;
//       }
//     }
//   }
// }

// @media screen and (max-width: 480px) {
//   .logo {
//     position: absolute;
//     &:nth-child(4) {
//       // Cicrle kenel
//       width: 10px !important;
//       height: 10px !important;
//     }

//     @for $i from 1 through 3 {
//       // Cicrle around
//       &:nth-child(#{$i}) {
//         width: 10vh !important;
//         height: 10vh !important;
//       }
//     }
//   }
// }
